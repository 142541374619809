import React, { useEffect, useState } from 'react';
import { Set } from '../models/exerciseModel';
import { FaStar, FaRegStar, FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface ExerciseCardProps {
    id: number;
    name: string;
    description: string;
    explanation: string;
    difficulty: number;
    muscleGroup: number;
    equipment: number;
    sets: Set[];
    onSetsChange: (sets: Set[]) => void;
    onRemoveExercise: () => void;
}

const muscleGroupMap: { [key: number]: string } = {
    0: 'Arms',
    1: 'Abs',
    2: 'Chest',
    3: 'Back',
    4: 'Shoulders',
    5: 'Legs',
    6: 'Glutes',
};

const equipmentMap: { [key: number]: string } = {
    0: 'Free Weights',
    1: 'Benches/Racks',
    2: 'Body Weight',
    3: 'Cable Machines',
    4: 'Machines',
};

const ExerciseCard: React.FC<ExerciseCardProps> = ({
    id,
    name,
    description,
    explanation,
    difficulty,
    muscleGroup,
    equipment,
    sets,
    onSetsChange,
    onRemoveExercise,
}) => {
    const [currentSets, setCurrentSets] = useState<Set[]>(sets);
    const [isExplanationOpen, setIsExplanationOpen] = useState<boolean>(false);

    useEffect(() => {
        setCurrentSets(sets);
    }, [sets]);

    const handleAddSet = () => {
        const newSets = [...currentSets, { repetitions: 0, weight: 0 }];
        setCurrentSets(newSets);
        onSetsChange(newSets);
    };

    const handleRemoveSet = (index: number) => {
        const newSets = currentSets.filter((_, i) => i !== index);
        setCurrentSets(newSets);
        onSetsChange(newSets);
    };

    const handleSetChange = (
        index: number,
        field: keyof Set,
        value: number
    ) => {
        const newSets = currentSets.map((set, i) =>
            i === index ? { ...set, [field]: value } : set
        );
        setCurrentSets(newSets);
        onSetsChange(newSets);
    };

    const renderDifficulty = (difficulty: number) => {
        const stars = [];
        for (let i = 1; i <= 3; i++) {
            stars.push(
                i <= difficulty ? (
                    <FaStar key={i} className="text-yellow-400" />
                ) : (
                    <FaRegStar key={i} className="text-yellow-400" />
                )
            );
        }
        return <div className="flex ml-2">{stars}</div>;
    };

    const formatExplanation = (explanation: string) => {
        return explanation
            .split(/(?=\d\.)/)
            .map((step, index) => <p key={index}>{step.trim()}</p>);
    };

    return (
        <div className="bg-gray-700 p-4 rounded-lg shadow-md relative w-full">
            <button
                className="absolute top-0 right-0 p-1 text-red-500 text-xl font-bold"
                onClick={onRemoveExercise}
            >
                &times;
            </button>
            <div className="text-center mb-4">
                <h3 className="text-lg font-bold inline-flex items-center">
                    {name}
                    {renderDifficulty(difficulty)}
                </h3>
            </div>
            <p>{description}</p>
            <p className="mt-2 font-semibold">
                Muscle Group: {muscleGroupMap[muscleGroup]}
            </p>
            <p className="font-semibold">
                Equipment: {equipmentMap[equipment]}
            </p>
            <div className="mt-2">
                <button
                    className="text-blue-500 font-bold flex items-center"
                    onClick={() => setIsExplanationOpen(!isExplanationOpen)}
                >
                    {isExplanationOpen ? (
                        <FaChevronUp className="mr-2" />
                    ) : (
                        <FaChevronDown className="mr-2" />
                    )}
                    {isExplanationOpen
                        ? 'Hide Explanation'
                        : 'Show Explanation'}
                </button>
                {isExplanationOpen && (
                    <div className="mt-2 bg-gray-600 p-2 rounded">
                        {formatExplanation(explanation)}
                    </div>
                )}
            </div>
            <div className="mt-4">
                <div className="flex justify-between items-center">
                    <h4 className="font-bold">Sets</h4>
                    <button
                        className="text-green-500 font-bold"
                        onClick={handleAddSet}
                    >
                        + Add Set
                    </button>
                </div>
                <ul>
                    {currentSets.map((set, index) => (
                        <li key={index} className="flex items-center mt-2">
                            <span className="font-bold mr-2">{index + 1}.</span>
                            <div className="flex-grow flex items-center">
                                <label className="mr-1">Reps:</label>
                                <input
                                    type="number"
                                    className="w-full p-1 rounded bg-gray-600 text-white mr-2"
                                    value={set.repetitions}
                                    onChange={(e) =>
                                        handleSetChange(
                                            index,
                                            'repetitions',
                                            Number(e.target.value)
                                        )
                                    }
                                    placeholder="Reps"
                                />
                            </div>
                            <div className="flex-grow flex items-center">
                                <label className="mr-1">KG:</label>
                                <input
                                    type="number"
                                    className="w-full p-1 rounded bg-gray-600 text-white mr-2"
                                    value={set.weight}
                                    onChange={(e) =>
                                        handleSetChange(
                                            index,
                                            'weight',
                                            Number(e.target.value)
                                        )
                                    }
                                    placeholder="Weight"
                                />
                            </div>
                            <button
                                className="text-red-500 font-bold ml-2 mr-2"
                                onClick={() => handleRemoveSet(index)}
                            >
                                -
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ExerciseCard;
