import React, { createContext, useEffect, useState } from 'react';
import { UserProfile } from '../models/userModel';
import { useNavigate } from 'react-router-dom';
import {
    loginAPI,
    registerAPI,
    updateUserAPI,
    uploadAvatarAPI,
} from '../services/AuthService';
import axios from 'axios';

type UserContextType = {
    user: UserProfile | null;
    token: string | null;
    registerUser: (
        email: string,
        username: string,
        password: string,
        firstName: string,
        lastName: string,
        inviteKey: string
    ) => Promise<{ success: boolean; message: string }>;
    loginUser: (
        emailOrUsername: string,
        password: string
    ) => Promise<{ success: boolean; message: string }>;
    updateUser: (
        updateData: Partial<UserProfile>,
        avatar?: File
    ) => Promise<{ success: boolean; message: string }>;
    logout: () => void;
    isLoggedIn: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('token');

        if (user && token) {
            setUser(JSON.parse(user));
            setToken(token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        setIsReady(true);
    }, []);

    const loginUser = async (
        emailOrUsername: string,
        password: string
    ): Promise<{ success: boolean; message: string }> => {
        const result = await loginAPI(emailOrUsername, password);
        if ('error' in result) {
            if (
                result.error ===
                'Please confirm your email address to activate your account.'
            ) {
                return { success: false, message: result.error };
            }
            return { success: false, message: result.error };
        }
        localStorage.setItem('token', result.token);
        const userObj: UserProfile = {
            email: result.email,
            username: result.username,
            firstName: result.firstName,
            lastName: result.lastName,
            avatar: result.avatar,
        };
        localStorage.setItem('user', JSON.stringify(userObj));
        setToken(result.token);
        setUser(userObj);
        return { success: true, message: 'Login Success!' };
    };

    const registerUser = async (
        email: string,
        username: string,
        password: string,
        firstName: string,
        lastName: string,
        inviteKey: string
    ): Promise<{ success: boolean; message: string }> => {
        const result = await registerAPI(
            email,
            username,
            password,
            firstName,
            lastName,
            inviteKey
        );
        if ('error' in result) {
            return { success: false, message: result.error };
        }
        return { success: true, message: 'Registration successful' };
    };

    const updateUser = async (
        updateData: Partial<UserProfile>,
        avatar?: File
    ): Promise<{ success: boolean; message: string }> => {
        if (!token) {
            return { success: false, message: 'Not authenticated' };
        }

        if (avatar) {
            const avatarUploadResult = await uploadAvatarAPI(token, avatar);
            if ('error' in avatarUploadResult) {
                return { success: false, message: avatarUploadResult.error };
            }
            updateData.avatar = avatarUploadResult.filename;
        }

        const result = await updateUserAPI(token, updateData);
        if ('error' in result) {
            return { success: false, message: result.error };
        }
        const updatedUser = { ...user, ...updateData } as UserProfile;
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);
        return { success: true, message: 'Profile updated successfully' };
    };

    const isLoggedIn = () => {
        return !!user;
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser(null);
        setToken('');
        navigate('/');
    };

    return (
        <UserContext.Provider
            value={{
                loginUser,
                user,
                token,
                logout,
                isLoggedIn,
                registerUser,
                updateUser,
            }}
        >
            {isReady ? children : null}
        </UserContext.Provider>
    );
};

export const useAuth = () => React.useContext(UserContext);
