import axios from 'axios';
import { handleError } from '../utils/ErrorHandler';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getWorkoutGroupsApi = async () => {
    try {
        const data = await axios.get(BASE_URL + 'workout/group/get/user');
        return data;
    } catch (error: any) {
        if (error.code === 'ERR_BAD_REQUEST') {
            toast.warn('No data found');
        } else {
            handleError(error);
        }
    }
};

export const createWorkoutGroupApi = async (group: {
    name: string;
    description?: string;
}) => {
    try {
        const data = await axios.post(BASE_URL + 'workout/group/add', group);
        return data;
    } catch (error) {
        handleError(error);
    }
};
