import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import EmailConfirmComponent from '../../components/EmailConfirmComponent';

type RegisterFormInputs = {
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    password: string;
    inviteKey: string;
    acceptPolicies: boolean;
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    username: Yup.string().required('Username is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    password: Yup.string()
        .min(3, 'Password must be at least 3 characters')
        .max(20, 'Password must be at most 20 characters')
        .required('Password is required'),
    inviteKey: Yup.string().required('Invite Key is required'),
    acceptPolicies: Yup.bool()
        .oneOf([true], 'You must accept the policies')
        .required('You must accept the policies'),
});

const RegisterPage: React.FC = () => {
    const { registerUser } = useAuth();
    const navigate = useNavigate();
    const [acceptPolicies, setAcceptPolicies] = useState(false);
    const [isAuthCodeSent, setIsAuthCodeSent] = useState(false);
    const [email, setEmail] = useState(''); // Store email for confirmation step
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterFormInputs>({
        resolver: yupResolver(validationSchema),
    });

    const handleRegister = async (data: RegisterFormInputs) => {
        console.log('Registering user...', data); // Debugging log
        try {
            const response = await registerUser(
                data.email,
                data.username,
                data.password,
                data.firstName,
                data.lastName,
                data.inviteKey
            );
            console.log('Register response:', response); // Debugging log
            if (response.success) {
                console.log('Setting isAuthCodeSent to true'); // Debugging log
                setEmail(data.email); // Store the email
                setIsAuthCodeSent(true);
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Registration failed:', error); // Debugging log
            toast.error('Registration failed', {
                className: 'toast-dark',
                progressClassName: 'toast-dark-progress-bar',
            });
        }
    };

    const handleEmailConfirmSuccess = () => {
        navigate('/login');
    };

    return (
        <section className="bg-gray-800">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full rounded-lg shadow border md:mb-20 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        {isAuthCodeSent ? (
                            <EmailConfirmComponent
                                email={email}
                                onSuccess={handleEmailConfirmSuccess}
                            />
                        ) : (
                            <>
                                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                                    Register an account
                                </h1>
                                <form
                                    className="space-y-4 md:space-y-6"
                                    onSubmit={handleSubmit(handleRegister)}
                                >
                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="block mb-2 text-sm font-medium text-white"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Email"
                                            {...register('email')}
                                        />
                                        {errors.email && (
                                            <p className="text-red-400">
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="username"
                                            className="block mb-2 text-sm font-medium text-white"
                                        >
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            id="username"
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Username"
                                            {...register('username')}
                                        />
                                        {errors.username && (
                                            <p className="text-red-400">
                                                {errors.username.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="firstName"
                                            className="block mb-2 text-sm font-medium text-white"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="First Name"
                                            {...register('firstName')}
                                        />
                                        {errors.firstName && (
                                            <p className="text-red-400">
                                                {errors.firstName.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="lastName"
                                            className="block mb-2 text-sm font-medium text-white"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Last Name"
                                            {...register('lastName')}
                                        />
                                        {errors.lastName && (
                                            <p className="text-red-400">
                                                {errors.lastName.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="inviteKey"
                                            className="block mb-2 text-sm font-medium text-white"
                                        >
                                            Invite Key
                                        </label>
                                        <input
                                            type="text"
                                            id="inviteKey"
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Invite Key"
                                            {...register('inviteKey')}
                                        />
                                        {errors.inviteKey && (
                                            <p className="text-red-400">
                                                {errors.inviteKey.message}
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="password"
                                            className="block mb-2 text-sm font-medium text-white"
                                        >
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Password"
                                            {...register('password')}
                                        />
                                        {errors.password && (
                                            <p className="text-red-400">
                                                {errors.password.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="acceptPolicies"
                                            className="mr-2"
                                            {...register('acceptPolicies')}
                                            onChange={() =>
                                                setAcceptPolicies(
                                                    !acceptPolicies
                                                )
                                            }
                                        />
                                        <label
                                            htmlFor="acceptPolicies"
                                            className="text-sm text-white"
                                        >
                                            I have read and accept the{' '}
                                            <a
                                                href="/privacy-policy"
                                                className="text-blue-500 hover:underline"
                                                target="_blank"
                                            >
                                                Privacy Policy
                                            </a>{' '}
                                            ,{' '}
                                            <a
                                                href="/cookie-policy"
                                                className="text-blue-500 hover:underline"
                                                target="_blank"
                                            >
                                                Cookie Policy
                                            </a>{' '}
                                            {'and '}
                                            <a
                                                href="/tos"
                                                className="text-blue-500 hover:underline"
                                                target="_blank"
                                            >
                                                TOS
                                            </a>
                                            .
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full text-white bg-green-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        Sign up
                                    </button>
                                    <p className="text-sm font-light text-gray-400">
                                        Already have an account?{' '}
                                        <a
                                            href="/login"
                                            className="font-medium text-primary-600 hover:underline"
                                        >
                                            Sign in
                                        </a>
                                    </p>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegisterPage;
