import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

interface Measurement {
    name: string;
    value: number;
}

interface StatProps {
    label: string;
    data: Measurement[];
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    unit: string;
}

const StatComponent: React.FC<StatProps> = ({
    label,
    data,
    value,
    onChange,
    unit,
}) => {
    return (
        <div className="bg-gray-700 p-4 rounded-lg shadow-md relative w-full">
            <h3 className="text-lg font-bold mb-2">{label}</h3>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart
                    data={data}
                    margin={{ top: 5, right: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={['dataMin - 1', 'dataMax + 1']} />
                    <Tooltip />
                    <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        strokeWidth={2}
                    />
                </LineChart>
            </ResponsiveContainer>
            <div className="mt-4 w-full">
                <label className="block text-white mb-1 font-medium">
                    {label} ({unit}):
                </label>
                <input
                    type="number"
                    name={label.toLowerCase().replace(/\s+/g, '')}
                    value={value}
                    onChange={onChange}
                    placeholder={`Enter ${label}...`}
                    className="input input-bordered w-full bg-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 rounded-lg"
                />
            </div>
        </div>
    );
};

export default StatComponent;
