import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-900 text-white py-6 mt-10">
            <div className="container mx-auto text-center">
                <div className="mb-4">
                    <h4 className="text-lg font-bold">Workout Tracker</h4>
                    <p className="text-gray-400">
                        Helping you track your workouts and progress.
                    </p>
                </div>
                <div className="flex justify-center space-x-4 mb-4">
                    <a
                        href="/privacy-policy"
                        className="text-blue-500 hover:text-blue-700"
                    >
                        Privacy Policy
                    </a>
                    <a
                        href="/cookie-policy"
                        className="text-blue-500 hover:text-blue-700"
                    >
                        Cookie Policy
                    </a>
                    <a
                        href="/tos"
                        className="text-blue-500 hover:text-blue-700"
                    >
                        Terms of Service
                    </a>
                </div>
                <p className="text-gray-400 text-sm">
                    &copy; {new Date().getFullYear()} Workout Tracker. All
                    rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
