import React from 'react';

const TOS: React.FC = () => {
    return (
        <div className="bg-gray-800 text-gray-300 p-6">
            <div className="max-w-2xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-white">
                        Privacy Policy
                    </h1>
                </div>

                <div className="space-y-6">
                    <h2 className="text-2xl font-bold text-white">
                        1. Overview of Data Protection
                    </h2>
                    <h3 className="text-xl font-semibold text-white">
                        General Information
                    </h3>
                    <p className="leading-relaxed">
                        The following information provides a simple overview of
                        what happens to your personal data when you visit our
                        website. Personal data are all data with which you can
                        be personally identified. Detailed information on the
                        subject of data protection can be found in our privacy
                        policy listed below this text.
                    </p>
                    <h3 className="text-xl font-semibold text-white">
                        Data Collection on Our Website
                    </h3>
                    <h4 className="text-lg font-semibold text-white">
                        Who is responsible for data collection on this website?
                    </h4>
                    <p className="leading-relaxed">
                        The data processing on this website is carried out by
                        the website operator. The contact details of the website
                        operator can be found in the section "Notice concerning
                        the responsible party" in this privacy policy.
                    </p>
                    <h4 className="text-lg font-semibold text-white">
                        How do we collect your data?
                    </h4>
                    <p className="leading-relaxed">
                        Your data will be collected on the one hand by you
                        communicating it to us. This can be, for example, data
                        that you enter in a contact form.
                    </p>
                    <p className="leading-relaxed">
                        Other data are collected automatically or after your
                        consent when you visit the website by our IT systems.
                        These are mainly technical data (e.g., internet browser,
                        operating system, or the time of the page call). The
                        collection of this data takes place automatically as
                        soon as you enter this website.
                    </p>
                    <h4 className="text-lg font-semibold text-white">
                        What do we use your data for?
                    </h4>
                    <p className="leading-relaxed">
                        A part of the data is collected to ensure error-free
                        provision of the website. Other data may be used to
                        analyze your user behavior.
                    </p>
                    <h4 className="text-lg font-semibold text-white">
                        What rights do you have regarding your data?
                    </h4>
                    <p className="leading-relaxed">
                        You have the right at any time to receive free
                        information about the origin, recipient, and purpose of
                        your stored personal data. You also have the right to
                        request the correction or deletion of these data. If you
                        have given consent for data processing, you can revoke
                        this consent at any time for the future. Furthermore,
                        you have the right to request the restriction of the
                        processing of your personal data under certain
                        circumstances. You also have the right to lodge a
                        complaint with the competent supervisory authority.
                    </p>
                    <p className="leading-relaxed">
                        You can contact us at any time regarding this and other
                        questions on the subject of data protection.
                    </p>

                    <h2 className="text-2xl font-bold text-white">
                        2. Hosting
                    </h2>
                    <p className="leading-relaxed">
                        We host the content of our website with the following
                        provider:
                    </p>
                    <h3 className="text-xl font-semibold text-white">
                        External Hosting
                    </h3>
                    <p className="leading-relaxed">
                        This website is hosted externally. The personal data
                        collected on this website are stored on the servers of
                        the host(s). This may include IP addresses, contact
                        requests, meta and communication data, contract data,
                        contact data, names, website accesses, and other data
                        generated via a website.
                    </p>
                    <p className="leading-relaxed">
                        External hosting is done for the purpose of fulfilling
                        contracts with our potential and existing customers
                        (Art. 6(1)(b) GDPR) and in the interest of secure, fast,
                        and efficient provision of our online offering by a
                        professional provider (Art. 6(1)(f) GDPR). If
                        corresponding consent has been requested, processing is
                        carried out exclusively on the basis of Art. 6(1)(a)
                        GDPR and § 25(1) TDDG, as far as the consent includes
                        the storage of cookies or the access to information in
                        the user's terminal device (e.g., device fingerprinting)
                        within the meaning of the TDDG. The consent is revocable
                        at any time.
                    </p>
                    <p className="leading-relaxed">
                        Our host(s) will only process your data to the extent
                        necessary to fulfill their performance obligations and
                        follow our instructions regarding this data.
                    </p>
                    <p className="leading-relaxed">
                        We use the following host(s):
                    </p>
                    <p className="leading-relaxed">
                        HOSTINGER operations, UAB
                        <br />
                        Švitrigailos str. 34, Vilnius 03230 Lithuania
                        <br />
                        Phone: +37064503378
                        <br />
                        Email: domains@hostinger.com
                    </p>
                    <h4 className="text-lg font-semibold text-white">
                        Order Processing
                    </h4>
                    <p className="leading-relaxed">
                        We have concluded a data processing agreement (DPA) for
                        the use of the above-mentioned service. This is a
                        legally required contract that ensures that this service
                        processes personal data of our website visitors only in
                        accordance with our instructions and in compliance with
                        the GDPR.
                    </p>

                    <h2 className="text-2xl font-bold text-white">
                        3. General Information and Mandatory Information
                    </h2>
                    <h3 className="text-xl font-semibold text-white">
                        Data Protection
                    </h3>
                    <p className="leading-relaxed">
                        The operators of these pages take the protection of your
                        personal data very seriously. We treat your personal
                        data confidentially and in accordance with the statutory
                        data protection regulations and this privacy policy.
                    </p>
                    <p className="leading-relaxed">
                        If you use this website, various personal data will be
                        collected. Personal data are data with which you can be
                        personally identified. This privacy policy explains
                        which data we collect and what we use them for. It also
                        explains how and for what purpose this happens.
                    </p>
                    <p className="leading-relaxed">
                        We point out that data transmission over the Internet
                        (e.g., communication by e-mail) can have security gaps.
                        Complete protection of the data against access by third
                        parties is not possible.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Notice Concerning the Responsible Party
                    </h3>
                    <p className="leading-relaxed">
                        The responsible party for data processing on this
                        website is:
                    </p>
                    <p className="leading-relaxed">Thomas Kargl</p>
                    <p className="leading-relaxed">
                        E-mail: support@gym-buddy.xyz
                    </p>
                    <p className="leading-relaxed">
                        The responsible party is the natural or legal person who
                        alone or jointly with others determines the purposes and
                        means of the processing of personal data (e.g., names,
                        e-mail addresses, etc.).
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Storage Duration
                    </h3>
                    <p className="leading-relaxed">
                        Unless a more specific storage period is mentioned
                        within this privacy policy, your personal data will
                        remain with us until the purpose for the data processing
                        no longer applies. If you make a legitimate request for
                        deletion or revoke your consent to data processing, your
                        data will be deleted unless we have other legally
                        permissible reasons for storing your personal data
                        (e.g., tax or commercial law retention periods); in the
                        latter case, deletion will take place after these
                        reasons no longer apply.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        General Information on the Legal Bases of Data
                        Processing on This Website
                    </h3>
                    <p className="leading-relaxed">
                        If you have consented to data processing, we process
                        your personal data on the basis of Art. 6(1)(a) GDPR or
                        Art. 9(2)(a) GDPR, if special categories of data
                        according to Art. 9(1) GDPR are processed. In the case
                        of an explicit consent to the transfer of personal data
                        to third countries, the data processing is also based on
                        Art. 49(1)(a) GDPR. If you have consented to the storage
                        of cookies or to the access to information in your
                        terminal device (e.g., via device fingerprinting), the
                        data processing is additionally based on § 25(1) TDDG.
                        The consent is revocable at any time. If your data are
                        necessary for the fulfillment of a contract or for the
                        implementation of pre-contractual measures, we process
                        your data on the basis of Art. 6(1)(b) GDPR.
                        Furthermore, we process your data if this is necessary
                        for the fulfillment of a legal obligation on the basis
                        of Art. 6(1)(c) GDPR. The data processing may also be
                        based on our legitimate interest according to Art.
                        6(1)(f) GDPR. Information on the relevant legal bases in
                        each individual case is provided in the following
                        paragraphs of this privacy policy.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Recipients of Personal Data
                    </h3>
                    <p className="leading-relaxed">
                        In the course of our business activities, we cooperate
                        with various external parties. This may also involve the
                        transfer of personal data to these external parties. We
                        only pass on personal data if this is necessary within
                        the framework of contract execution, if we are legally
                        obliged to do so (e.g., transfer of data to tax
                        authorities), if we have a legitimate interest according
                        to Art. 6(1)(f) GDPR in the data transfer, or if another
                        legal basis allows the data transfer. When using
                        processors, we only pass on personal data of our
                        customers on the basis of a valid data processing
                        agreement. In the case of joint processing, a joint
                        processing agreement is concluded.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Revocation of Your Consent to Data Processing
                    </h3>
                    <p className="leading-relaxed">
                        Many data processing operations are only possible with
                        your express consent. You can revoke an already given
                        consent at any time. The legality of the data processing
                        carried out until the revocation remains unaffected by
                        the revocation.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Right to Object to Data Collection in Special Cases and
                        to Direct Advertising (Art. 21 GDPR)
                    </h3>
                    <p className="leading-relaxed">
                        IF THE DATA PROCESSING IS BASED ON ART. 6(1)(E) OR (F)
                        GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF
                        YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM
                        YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO
                        PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE
                        LEGAL BASIS ON WHICH A PROCESSING IS BASED CAN BE FOUND
                        IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER
                        PROCESS YOUR AFFECTED PERSONAL DATA UNLESS WE CAN
                        DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE
                        PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS, AND
                        FREEDOMS OR THE PROCESSING SERVES THE ASSERTION,
                        EXERCISE, OR DEFENSE OF LEGAL CLAIMS (OBJECTION
                        ACCORDING TO ART. 21(1) GDPR).
                    </p>
                    <p className="leading-relaxed">
                        IF YOUR PERSONAL DATA ARE PROCESSED TO CARRY OUT DIRECT
                        ADVERTISING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO
                        THE PROCESSING OF YOUR PERSONAL DATA FOR THE PURPOSE OF
                        SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING,
                        INSOFAR AS IT IS ASSOCIATED WITH SUCH DIRECT
                        ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL THEN
                        NO LONGER BE USED FOR THE PURPOSE OF DIRECT ADVERTISING
                        (OBJECTION ACCORDING TO ART. 21(2) GDPR).
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Right to Lodge a Complaint with the Competent
                        Supervisory Authority
                    </h3>
                    <p className="leading-relaxed">
                        In the event of breaches of the GDPR, data subjects have
                        the right to lodge a complaint with a supervisory
                        authority, particularly in the Member State of their
                        habitual residence, place of work, or the place of the
                        alleged infringement. The right to lodge a complaint is
                        without prejudice to any other administrative or
                        judicial remedy.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Right to Data Portability
                    </h3>
                    <p className="leading-relaxed">
                        You have the right to have data which we process on the
                        basis of your consent or in fulfillment of a contract
                        automatically delivered to yourself or to a third party
                        in a common, machine-readable format. If you request the
                        direct transfer of the data to another controller, this
                        will only be done to the extent technically feasible.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Information, Correction, and Deletion
                    </h3>
                    <p className="leading-relaxed">
                        Within the scope of the applicable legal provisions, you
                        have the right at any time to free information about
                        your stored personal data, their origin and recipients,
                        and the purpose of data processing and, if necessary, a
                        right to correction or deletion of these data. For this
                        purpose, as well as for further questions on the subject
                        of personal data, you can contact us at any time.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Right to Restriction of Processing
                    </h3>
                    <p className="leading-relaxed">
                        You have the right to request the restriction of the
                        processing of your personal data. For this purpose, you
                        can contact us at any time. The right to restriction of
                        processing exists in the following cases:
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            If you dispute the accuracy of your personal data
                            stored by us, we usually need time to verify this.
                            For the duration of the verification, you have the
                            right to request the restriction of the processing
                            of your personal data.
                        </li>
                        <li>
                            If the processing of your personal data was/is
                            unlawful, you can request the restriction of data
                            processing instead of deletion.
                        </li>
                        <li>
                            If we no longer need your personal data, but you
                            need them to exercise, defend, or assert legal
                            claims, you have the right to request the
                            restriction of the processing of your personal data
                            instead of deletion.
                        </li>
                        <li>
                            If you have lodged an objection under Art. 21(1)
                            GDPR, a balance must be struck between your and our
                            interests. As long as it is not yet clear whose
                            interests prevail, you have the right to request the
                            restriction of the processing of your personal data.
                        </li>
                    </ul>
                    <p className="leading-relaxed">
                        If you have restricted the processing of your personal
                        data, these data may – apart from their storage – only
                        be processed with your consent or for the assertion,
                        exercise, or defense of legal claims or for the
                        protection of the rights of another natural or legal
                        person or for reasons of important public interest of
                        the European Union or a Member State.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        SSL or TLS Encryption
                    </h3>
                    <p className="leading-relaxed">
                        For security reasons and to protect the transmission of
                        confidential content, such as orders or inquiries that
                        you send to us as the site operator, this site uses SSL
                        or TLS encryption. You can recognize an encrypted
                        connection by the fact that the address line of the
                        browser changes from "http://" to "https://" and by the
                        lock symbol in your browser line.
                    </p>
                    <p className="leading-relaxed">
                        If SSL or TLS encryption is activated, the data you
                        transmit to us cannot be read by third parties.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Objection to Advertising Emails
                    </h3>
                    <p className="leading-relaxed">
                        We hereby object to the use of contact data published
                        within the framework of the imprint obligation to send
                        unsolicited advertising and information material. The
                        operators of these pages expressly reserve the right to
                        take legal action in the event of unsolicited
                        advertising information, such as spam emails.
                    </p>

                    <h2 className="text-2xl font-bold text-white">
                        4. Data Collection on Our Website
                    </h2>
                    <h3 className="text-xl font-semibold text-white">
                        Cookies
                    </h3>
                    <p className="leading-relaxed">
                        Our internet pages use so-called "cookies." Cookies are
                        small data packets and do not cause any damage to your
                        terminal device. They are either temporarily stored for
                        the duration of a session (session cookies) or
                        permanently (permanent cookies) on your terminal device.
                        Session cookies are automatically deleted after your
                        visit. Permanent cookies remain stored on your terminal
                        device until you delete them yourself or an automatic
                        deletion by your web browser occurs.
                    </p>
                    <p className="leading-relaxed">
                        Cookies can be from us (first-party cookies) or from
                        third-party companies (third-party cookies). Third-party
                        cookies enable the integration of certain services of
                        third-party companies within websites (e.g., cookies for
                        processing payment services).
                    </p>
                    <p className="leading-relaxed">
                        Cookies have various functions. Many cookies are
                        technically necessary, as certain website functions
                        would not work without them (e.g., the shopping cart
                        function or displaying videos). Other cookies can be
                        used to evaluate user behavior or for advertising
                        purposes.
                    </p>
                    <p className="leading-relaxed">
                        Cookies that are necessary to carry out the electronic
                        communication process, to provide certain functions you
                        have requested (e.g., for the shopping cart function),
                        or to optimize the website (e.g., cookies to measure the
                        web audience) are stored on the basis of Art. 6(1)(f)
                        GDPR, unless another legal basis is specified. The
                        website operator has a legitimate interest in storing
                        necessary cookies for the technically error-free and
                        optimized provision of its services. If consent to the
                        storage of cookies and comparable recognition
                        technologies has been requested, processing is carried
                        out exclusively on the basis of this consent (Art.
                        6(1)(a) GDPR and § 25(1) TDDG); the consent is revocable
                        at any time.
                    </p>
                    <p className="leading-relaxed">
                        You can set your browser so that you are informed about
                        the setting of cookies and allow cookies only in
                        individual cases, exclude the acceptance of cookies for
                        certain cases or in general, and activate the automatic
                        deletion of cookies when closing the browser. When
                        cookies are deactivated, the functionality of this
                        website may be limited.
                    </p>
                    <p className="leading-relaxed">
                        Which cookies and services are used on this website can
                        be found in this privacy policy.
                    </p>

                    <h3 className="text-xl font-semibold text-white">
                        Inquiry by Email, Phone, or Fax
                    </h3>
                    <p className="leading-relaxed">
                        If you contact us by email, phone, or fax, your request,
                        including all resulting personal data (name, request),
                        will be stored and processed by us for the purpose of
                        processing your request. We do not pass on these data
                        without your consent.
                    </p>
                    <p className="leading-relaxed">
                        The processing of these data is based on Art. 6(1)(b)
                        GDPR, if your request is related to the performance of a
                        contract or is necessary for the implementation of
                        pre-contractual measures. In all other cases, the
                        processing is based on our legitimate interest in the
                        effective processing of the inquiries addressed to us
                        (Art. 6(1)(f) GDPR) or on your consent (Art. 6(1)(a)
                        GDPR), if this has been requested; the consent is
                        revocable at any time.
                    </p>
                    <p className="leading-relaxed">
                        The data sent by you to us via contact requests remain
                        with us until you request us to delete, revoke your
                        consent to the storage, or the purpose for the data
                        storage no longer applies (e.g., after the completed
                        processing of your request). Mandatory statutory
                        provisions – in particular statutory retention periods –
                        remain unaffected.
                    </p>

                    <h2 className="text-2xl font-bold text-white">
                        5. Plugins and Tools
                    </h2>
                    <h3 className="text-xl font-semibold text-white">
                        Font Awesome (local hosting)
                    </h3>
                    <p className="leading-relaxed">
                        This site uses Font Awesome for the uniform
                        representation of fonts. Font Awesome is installed
                        locally. There is no connection to the servers of
                        Fonticons, Inc.
                    </p>
                    <p className="leading-relaxed">
                        Further information about Font Awesome can be found in
                        the privacy policy for Font Awesome at:{' '}
                        <a
                            href="https://fontawesome.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-400"
                        >
                            https://fontawesome.com/privacy
                        </a>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TOS;
