import React, { useEffect, useState, useCallback } from 'react';
import * as _ from 'lodash';
import {
    searchExerciseApi,
    saveWorkoutApi,
    getLastWorkoutByGroupIdApi,
    addExerciseApi,
    deleteWorkoutGroupApi,
} from '../services/ExerciseWorkoutService';
import ExerciseCard from '../components/ExerciseCard';
import WorkoutGroupManager from '../components/WorkoutGroupManager';
import { Exercise, Set, AddExerciseDto } from '../models/exerciseModel';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AddExerciseForm from '../components/AddExerciseForm';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const WorkoutPage: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [lastSearchTerm, setLastSearchTerm] = useState<string>('');
    const [userExercises, setUserExercises] = useState<Exercise[]>([]);
    const [publicExercises, setPublicExercises] = useState<Exercise[]>([]);
    const [selectedExercises, setSelectedExercises] = useState<
        { exercise: Exercise; sets: Set[] }[]
    >([]);
    const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isCreatingNewGroup, setIsCreatingNewGroup] =
        useState<boolean>(false);
    const [noExercisesMessage, setNoExercisesMessage] = useState<string>('');
    const [noSearchResult, setNoSearchResult] = useState<boolean>(false);
    const [showAddExerciseForm, setShowAddExerciseForm] =
        useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] =
        useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
        useState<boolean>(false);

    const searchExercises = useCallback(async (query: string) => {
        if (query.length < 3) {
            setUserExercises([]);
            setPublicExercises([]);
            setIsLoading(false);
            return;
        }
        setIsLoading(true);

        await searchExerciseApi(query)
            .then((res) => {
                if (res) {
                    setUserExercises(res.userExercises);
                    setPublicExercises(res.publicExercises);
                    setError('');
                    if (
                        res.userExercises.length === 0 &&
                        res.publicExercises.length === 0
                    ) {
                        setNoSearchResult(true);
                        setError('No exercises found.');
                    } else {
                        setNoSearchResult(false);
                    }
                }
            })
            .catch((e) => {
                if (e.response && e.response.status === 404) {
                    setNoSearchResult(true);
                    setError('No exercises found.');
                } else if (e.response && e.response.status === 429) {
                    setError('Too many requests. Please try again later.');
                } else {
                    setError('Failed to fetch exercises.');
                }
                setUserExercises([]);
                setPublicExercises([]);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const debouncedSearch = useCallback(_.debounce(searchExercises, 300), [
        searchExercises,
    ]);

    useEffect(() => {
        if (searchTerm !== lastSearchTerm) {
            debouncedSearch(searchTerm);
            setLastSearchTerm(searchTerm);
        }
    }, [searchTerm, lastSearchTerm, debouncedSearch]);

    useEffect(() => {
        if (selectedGroupId !== null) {
            loadLastWorkoutByGroupId(selectedGroupId);
        }
    }, [selectedGroupId]);

    const loadLastWorkoutByGroupId = async (groupId: number) => {
        setSelectedExercises([]);
        setNoExercisesMessage('');
        try {
            const res = await getLastWorkoutByGroupIdApi(groupId);
            if (res && res.data) {
                const exercises = res.data.exercises.map(
                    (exercise: { exercise: Exercise; sets: Set[] }) => ({
                        exercise: exercise.exercise,
                        sets: exercise.sets,
                    })
                );
                setSelectedExercises(exercises);
                if (exercises.length === 0) {
                    setNoExercisesMessage(
                        'No exercises are present in this group. Use the search function to add some.'
                    );
                }
            } else {
                setNoExercisesMessage(
                    'No exercises are present in this group. Use the search function to add some.'
                );
            }
        } catch (e) {
            setNoExercisesMessage(
                'No exercises are present in this group. Use the search function to add some.'
            );
        }
    };

    const handleSelectExercise = (exercise: Exercise) => {
        if (!selectedExercises.find((ex) => ex.exercise.id === exercise.id)) {
            setSelectedExercises((prev) => [
                ...prev,
                { exercise, sets: [{ repetitions: 0, weight: 0 }] },
            ]);
            setNoExercisesMessage('');
            setSearchTerm('');
            setUserExercises([]);
            setPublicExercises([]);
        }
    };

    const handleSetsChange = (exerciseId: number, sets: Set[]) => {
        setSelectedExercises((prev) =>
            prev.map((ex) =>
                ex.exercise.id === exerciseId ? { ...ex, sets } : ex
            )
        );
    };

    const handleSaveWorkout = async () => {
        try {
            await saveWorkoutApi(selectedGroupId, selectedExercises);
            toast.success('Workout saved');
        } catch (err) {
            toast.error('Failed to save workout');
        }
    };

    const handleDeleteWorkout = async () => {
        if (selectedGroupId !== null) {
            try {
                await deleteWorkoutGroupApi(selectedGroupId);
                toast.success('Workout group deleted');
                setSelectedExercises([]);
                setSelectedGroupId(null);
                window.location.reload();
            } catch (err) {
                toast.error('Failed to delete workout group');
            } finally {
                setShowDeleteConfirmationModal(false);
            }
        } else {
            toast.error('No workout group selected');
        }
    };

    const handleRemoveExercise = (exerciseId: number) => {
        const newSelectedExercises = selectedExercises.filter(
            (exercise) => exercise.exercise.id !== exerciseId
        );
        setSelectedExercises(newSelectedExercises);
        if (newSelectedExercises.length === 0) {
            setNoExercisesMessage(
                'No exercises are present in this group. Use the search function to add some.'
            );
        }
    };

    const handleGroupSelect = (groupId: number) => {
        setSelectedGroupId(groupId);
        setIsCreatingNewGroup(false);
        loadLastWorkoutByGroupId(groupId);
    };

    const handleCreateNewGroup = () => {
        setIsCreatingNewGroup(true);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        setUserExercises([]);
        setPublicExercises([]);
        setError('');
    };

    const handleAddExercise = async (exercise: AddExerciseDto) => {
        try {
            await addExerciseApi(exercise);
            toast.success('Exercise added successfully');
            setShowAddExerciseForm(false);
            setSearchTerm('');
        } catch (err) {
            toast.error('Failed to add exercise');
        }
    };

    const openConfirmationModal = () => {
        setShowConfirmationModal(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const confirmSaveWorkout = async () => {
        await handleSaveWorkout();
        closeConfirmationModal();
    };

    const openDeleteConfirmationModal = () => {
        setShowDeleteConfirmationModal(true);
    };

    const closeDeleteConfirmationModal = () => {
        setShowDeleteConfirmationModal(false);
    };

    const confirmDeleteWorkout = async () => {
        closeDeleteConfirmationModal();
        await handleDeleteWorkout();
    };

    return (
        <div className="container mx-auto p-6 text-white min-h-screen bg-gray-800">
            <h2 className="text-3xl font-bold mb-4">Manage Your Workouts</h2>
            <p className="text-gray-400 mb-8">
                Use this page to create and manage your workout groups. Select a
                group to view and add exercises. You can search for exercises
                using the search bar and add them to your selected group. Save
                your workouts to track your progress over time.
            </p>
            <WorkoutGroupManager
                onGroupSelect={handleGroupSelect}
                onCreateNewGroup={handleCreateNewGroup}
            />
            {(isCreatingNewGroup || selectedGroupId !== null) && (
                <>
                    <div className="mt-6">
                        <h3 className="text-2xl font-semibold mb-4">
                            Search for Exercises
                        </h3>
                        <div className="relative w-full max-w-md mb-4">
                            <div className="relative flex items-center">
                                <FaSearch className="absolute left-3 text-gray-400" />
                                <input
                                    className="input input-bordered w-full pl-10 bg-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 rounded-lg"
                                    type="text"
                                    placeholder="Search exercises..."
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                />
                                {searchTerm && (
                                    <FaTimes
                                        className="absolute right-3 text-red-500 cursor-pointer"
                                        onClick={handleClearSearch}
                                    />
                                )}
                            </div>
                            {isLoading && (
                                <div className="absolute top-full left-0 w-full text-center text-sm">
                                    Loading...
                                </div>
                            )}
                            {error && (
                                <div className="absolute top-full left-0 w-full text-center text-red-500 text-sm">
                                    {error}
                                </div>
                            )}
                            {noSearchResult && (
                                <div className="absolute left-0 right-0 top-full bg-gray-800 shadow-lg mt-1 rounded-lg z-50 p-2 text-red-500 text-center">
                                    No exercises found.
                                    <button
                                        onClick={() => {
                                            setShowAddExerciseForm(true);
                                            setNoSearchResult(false); // Close the search result box
                                        }}
                                        className=" ml-3 mt-4 inline-block bg-blue-700 hover:bg-blue-900 text-white font-semibold p-2 rounded"
                                    >
                                        Add Own
                                    </button>
                                </div>
                            )}
                            {(userExercises.length > 0 ||
                                publicExercises.length > 0) && (
                                <ul className="absolute left-0 right-0 top-full bg-gray-800 shadow-lg max-h-60 overflow-auto mt-1 rounded-lg z-50">
                                    {userExercises.map((exercise) => (
                                        <li
                                            key={exercise.id}
                                            className="p-2 bg-zinc-800 text-white rounded-md cursor-pointer hover:bg-zinc-700"
                                            onClick={() =>
                                                handleSelectExercise(exercise)
                                            }
                                        >
                                            {exercise.name}
                                        </li>
                                    ))}
                                    {publicExercises.map((exercise) => (
                                        <li
                                            key={exercise.id}
                                            className="p-2 bg-gray-800 text-white rounded-md cursor-pointer hover:bg-gray-700"
                                            onClick={() =>
                                                handleSelectExercise(exercise)
                                            }
                                        >
                                            {exercise.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {noExercisesMessage && (
                            <p className="text-gray-400 text-center">
                                {noExercisesMessage}
                            </p>
                        )}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                        {selectedExercises.map((exercise) => (
                            <ExerciseCard
                                key={exercise.exercise.id}
                                id={exercise.exercise.id}
                                name={exercise.exercise.name}
                                description={exercise.exercise.description}
                                explanation={exercise.exercise.explanation}
                                difficulty={exercise.exercise.difficulty}
                                muscleGroup={exercise.exercise.muscleGroup}
                                equipment={exercise.exercise.equipment}
                                sets={exercise.sets}
                                onSetsChange={(sets) =>
                                    handleSetsChange(exercise.exercise.id, sets)
                                }
                                onRemoveExercise={() =>
                                    handleRemoveExercise(exercise.exercise.id)
                                }
                            />
                        ))}
                    </div>
                    {selectedExercises.length > 0 && (
                        <div className="text-center mt-6">
                            <button
                                onClick={openConfirmationModal}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                            >
                                Save Workout
                            </button>
                            <button
                                onClick={openDeleteConfirmationModal}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Delete Workout
                            </button>
                        </div>
                    )}
                </>
            )}
            <Modal
                isOpen={showAddExerciseForm}
                onRequestClose={() => setShowAddExerciseForm(false)}
                contentLabel="Add Exercise"
                className="Modal"
                overlayClassName="Overlay"
            >
                <AddExerciseForm
                    onSave={handleAddExercise}
                    onCancel={() => setShowAddExerciseForm(false)}
                />
            </Modal>
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={closeConfirmationModal}
                contentLabel="Confirm Save Workout"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="bg-gray-900 p-6 rounded-lg shadow-lg text-white">
                    <h3 className="text-2xl mb-4">Confirm Save Workout</h3>
                    <p className="mb-4">
                        Are you sure you want to save this workout? Please only
                        do this if you are truly finished.
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={closeConfirmationModal}
                            className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={confirmSaveWorkout}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={showDeleteConfirmationModal}
                onRequestClose={closeDeleteConfirmationModal}
                contentLabel="Confirm Delete Workout"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="bg-gray-900 p-6 rounded-lg shadow-lg text-white">
                    <h3 className="text-2xl mb-4">Confirm Delete Workout</h3>
                    <p className="mb-4">
                        Are you sure you want to delete this workout group? This
                        action cannot be undone.
                    </p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={closeDeleteConfirmationModal}
                            className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={confirmDeleteWorkout}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default WorkoutPage;
