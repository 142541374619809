import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const NotFound: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-800 text-white">
            <div className="text-center">
                <h1 className="text-6xl font-bold mb-4">404</h1>
                <h2 className="text-2xl font-semibold mb-8">Page Not Found</h2>
                <p className="text-gray-400 mb-6">
                    The page you are looking for does not exist or has been
                    moved.
                </p>
                <Link
                    to="/"
                    className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    <FaHome className="mr-2" />
                    Go Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
