import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-800 text-gray-300 p-6">
            <div className="max-w-2xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-white">
                        PRIVACY POLICY
                    </h1>
                    <p className="text-sm text-gray-400 mt-2">
                        Last updated May 29, 2024
                    </p>
                </div>

                <div className="space-y-6">
                    <p className="leading-relaxed">
                        This privacy notice for <strong>Gym Buddy</strong>{' '}
                        ("we", "us", or "our"), describes how and why we might
                        collect, store, use, and/or share ("process") your
                        information when you use our services ("Services"), such
                        as when you:
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            Visit our website at{' '}
                            <a
                                href="https://www.gym-buddy.xyz"
                                className="text-blue-500 hover:underline"
                            >
                                https://www.gym-buddy.xyz
                            </a>
                            , or any website of ours that links to this privacy
                            notice
                        </li>
                        <li>
                            Engage with us in other related ways, including any
                            sales, marketing, or events
                        </li>
                    </ul>
                    <p className="leading-relaxed">
                        <strong>Questions or concerns?</strong> Reading this
                        privacy notice will help you understand your privacy
                        rights and choices. If you do not agree with our
                        policies and practices, please do not use our Services.
                        If you still have any questions or concerns, please
                        contact us at{' '}
                        <a
                            href="mailto:support@gym-buddy.xyz"
                            className="text-blue-500 hover:underline"
                        >
                            support@gym-buddy.xyz
                        </a>
                        .
                    </p>

                    <h2 className="text-2xl font-bold text-white">
                        SUMMARY OF KEY POINTS
                    </h2>
                    <p className="leading-relaxed">
                        <strong>
                            <em>
                                This summary provides key points from our
                                privacy notice, but you can find out more
                                details about any of these topics by clicking
                                the link following each key point or by using
                                our{' '}
                            </em>
                        </strong>
                        <a
                            href="#toc"
                            className="text-blue-500 hover:underline"
                        >
                            <strong>
                                <em>table of contents</em>
                            </strong>
                        </a>
                        <strong>
                            <em>
                                {' '}
                                below to find the section you are looking for.
                            </em>
                        </strong>
                    </p>

                    <div className="space-y-4">
                        <div>
                            <h3 className="text-xl font-bold text-white">
                                What personal information do we process?
                            </h3>
                            <p className="leading-relaxed">
                                When you visit, use, or navigate our Services,
                                we may process personal information depending on
                                how you interact with us and the Services, the
                                choices you make, and the products and features
                                you use. Learn more about{' '}
                                <a
                                    href="#personalinfo"
                                    className="text-blue-500 hover:underline"
                                >
                                    personal information you disclose to us
                                </a>
                                .
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                Do we process any sensitive personal
                                information?
                            </h3>
                            <p className="leading-relaxed">
                                We may process sensitive personal information
                                when necessary with your consent or as otherwise
                                permitted by applicable law. Learn more about{' '}
                                <a
                                    href="#sensitiveinfo"
                                    className="text-blue-500 hover:underline"
                                >
                                    sensitive information we process
                                </a>
                                .
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                Do we collect any information from third
                                parties?
                            </h3>
                            <p className="leading-relaxed">
                                We do not collect any information from third
                                parties.
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                How do we process your information?
                            </h3>
                            <p className="leading-relaxed">
                                We process your information to provide, improve,
                                and administer our Services, communicate with
                                you, for security and fraud prevention, and to
                                comply with law. We may also process your
                                information for other purposes with your
                                consent. Learn more about{' '}
                                <a
                                    href="#infouse"
                                    className="text-blue-500 hover:underline"
                                >
                                    how we process your information
                                </a>
                                .
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                In what situations and with which parties do we
                                share personal information?
                            </h3>
                            <p className="leading-relaxed">
                                We may share information in specific situations
                                and with specific third parties. Learn more
                                about{' '}
                                <a
                                    href="#whoshare"
                                    className="text-blue-500 hover:underline"
                                >
                                    when and with whom we share your personal
                                    information
                                </a>
                                .
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                How do we keep your information safe?
                            </h3>
                            <p className="leading-relaxed">
                                We have organisational and technical processes
                                and procedures in place to protect your personal
                                information. However, no electronic transmission
                                over the internet or information storage
                                technology can be guaranteed to be 100% secure,
                                so we cannot promise or guarantee that hackers,
                                cybercriminals, or other unauthorised third
                                parties will not be able to defeat our security
                                and improperly collect, access, steal, or modify
                                your information. Learn more about{' '}
                                <a
                                    href="#infosafe"
                                    className="text-blue-500 hover:underline"
                                >
                                    how we keep your information safe
                                </a>
                                .
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                What are your rights?
                            </h3>
                            <p className="leading-relaxed">
                                Depending on where you are located
                                geographically, the applicable privacy law may
                                mean you have certain rights regarding your
                                personal information. Learn more about{' '}
                                <a
                                    href="#privacyrights"
                                    className="text-blue-500 hover:underline"
                                >
                                    your privacy rights
                                </a>
                                .
                            </p>
                        </div>

                        <div>
                            <h3 className="text-xl font-bold text-white">
                                How do you exercise your rights?
                            </h3>
                            <p className="leading-relaxed">
                                The easiest way to exercise your rights is by
                                visiting{' '}
                                <a
                                    href="https://gym-buddy.xyz/data-request"
                                    className="text-blue-500 hover:underline"
                                >
                                    gym-buddy.xyz/data-request
                                </a>
                                , or by contacting us. We will consider and act
                                upon any request in accordance with applicable
                                data protection laws.
                            </p>
                        </div>
                    </div>

                    <p className="leading-relaxed">
                        Want to learn more about what we do with any information
                        we collect?{' '}
                        <a
                            href="#toc"
                            className="text-blue-500 hover:underline"
                        >
                            Review the privacy notice in full
                        </a>
                        .
                    </p>

                    <div className="space-y-4">
                        <h2 id="toc" className="text-2xl font-bold text-white">
                            TABLE OF CONTENTS
                        </h2>
                        <ul className="list-disc list-inside space-y-2">
                            <li>
                                <a
                                    href="#infocollect"
                                    className="text-blue-500 hover:underline"
                                >
                                    1. WHAT INFORMATION DO WE COLLECT?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#infouse"
                                    className="text-blue-500 hover:underline"
                                >
                                    2. HOW DO WE PROCESS YOUR INFORMATION?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#legalbases"
                                    className="text-blue-500 hover:underline"
                                >
                                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS
                                    YOUR PERSONAL INFORMATION?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#whoshare"
                                    className="text-blue-500 hover:underline"
                                >
                                    4. WHEN AND WITH WHOM DO WE SHARE YOUR
                                    PERSONAL INFORMATION?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#3pwebsites"
                                    className="text-blue-500 hover:underline"
                                >
                                    5. WHAT IS OUR STANCE ON THIRD-PARTY
                                    WEBSITES?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#cookies"
                                    className="text-blue-500 hover:underline"
                                >
                                    6. DO WE USE COOKIES AND OTHER TRACKING
                                    TECHNOLOGIES?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#inforetain"
                                    className="text-blue-500 hover:underline"
                                >
                                    7. HOW LONG DO WE KEEP YOUR INFORMATION?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#infosafe"
                                    className="text-blue-500 hover:underline"
                                >
                                    8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#privacyrights"
                                    className="text-blue-500 hover:underline"
                                >
                                    9. WHAT ARE YOUR PRIVACY RIGHTS?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#DNT"
                                    className="text-blue-500 hover:underline"
                                >
                                    10. CONTROLS FOR DO-NOT-TRACK FEATURES
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#policyupdates"
                                    className="text-blue-500 hover:underline"
                                >
                                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#contact"
                                    className="text-blue-500 hover:underline"
                                >
                                    12. HOW CAN YOU CONTACT US ABOUT THIS
                                    NOTICE?
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#request"
                                    className="text-blue-500 hover:underline"
                                >
                                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE
                                    THE DATA WE COLLECT FROM YOU?
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="infocollect"
                            className="text-2xl font-bold text-white"
                        >
                            1. WHAT INFORMATION DO WE COLLECT?
                        </h2>
                        <h3
                            id="personalinfo"
                            className="text-xl font-bold text-white"
                        >
                            Personal information you disclose to us
                        </h3>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We collect personal
                            information that you provide to us.
                        </p>
                        <p className="leading-relaxed">
                            We collect personal information that you voluntarily
                            provide to us when you register on the Services,
                            express an interest in obtaining information about
                            us or our products and Services, when you
                            participate in activities on the Services, or
                            otherwise when you contact us.
                        </p>

                        <h3
                            id="sensitiveinfo"
                            className="text-xl font-bold text-white"
                        >
                            Sensitive Information
                        </h3>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> When necessary, with your
                            consent or as otherwise permitted by applicable law,
                            we process the following categories of sensitive
                            information:
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>Health data</li>
                            {/* <li>Financial data</li>
                            <li>Biometric data</li>
                            <li>Genetic data</li> */}
                        </ul>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="infouse"
                            className="text-2xl font-bold text-white"
                        >
                            2. HOW DO WE PROCESS YOUR INFORMATION?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We process your
                            information to provide, improve, and administer our
                            Services, communicate with you, for security and
                            fraud prevention, and to comply with law. We may
                            also process your information for other purposes
                            with your consent.
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>
                                <strong>
                                    To facilitate account creation and
                                    authentication and otherwise manage user
                                    accounts:
                                </strong>{' '}
                                We may process your information so you can
                                create and log in to your account, as well as
                                keep your account in working order.
                            </li>
                            <li>
                                <strong>
                                    To deliver and facilitate delivery of
                                    services to the user:
                                </strong>{' '}
                                We may process your information to provide you
                                with the requested service.
                            </li>
                            <li>
                                <strong>
                                    To send administrative information to you:
                                </strong>{' '}
                                We may process your information to send you
                                details about our products and services, changes
                                to our terms and policies, and other similar
                                information.
                            </li>
                            <li>
                                <strong>To protect our Services:</strong> We may
                                process your information as part of our efforts
                                to keep our Services safe and secure, including
                                fraud monitoring and prevention.
                            </li>
                            <li>
                                <strong>
                                    To save or protect an individual's vital
                                    interest:
                                </strong>{' '}
                                We may process your information when necessary
                                to save or protect an individual’s vital
                                interest, such as to prevent harm.
                            </li>
                        </ul>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="legalbases"
                            className="text-2xl font-bold text-white"
                        >
                            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                            PERSONAL INFORMATION?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We only process your
                            personal information when we believe it is necessary
                            and we have a valid legal reason (i.e. legal basis)
                            to do so under applicable law, like with your
                            consent, to comply with laws, to provide you with
                            services to enter into or fulfil our contractual
                            obligations, to protect your rights, or to fulfil
                            our legitimate business interests.
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>
                                <strong>Consent:</strong> We may process your
                                information if you have given us permission
                                (i.e. consent) to use your personal information
                                for a specific purpose. You can withdraw your
                                consent at any time. Learn more about{' '}
                                <a
                                    href="#withdrawconsent"
                                    className="text-blue-500 hover:underline"
                                >
                                    withdrawing your consent
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Performance of a Contract:</strong> We
                                may process your personal information when we
                                believe it is necessary to fulfil our
                                contractual obligations to you, including
                                providing our Services or at your request prior
                                to entering into a contract with you.
                            </li>
                            <li>
                                <strong>Legitimate Interests:</strong> We may
                                process your information when we believe it is
                                reasonably necessary to achieve our legitimate
                                business interests and those interests do not
                                outweigh your interests and fundamental rights
                                and freedoms. For example, we may process your
                                personal information for some of the purposes
                                described in order to diagnose problems and/or
                                prevent fraudulent activities.
                            </li>
                            <li>
                                <strong>Legal Obligations:</strong> We may
                                process your information where we believe it is
                                necessary for compliance with our legal
                                obligations, such as to cooperate with a law
                                enforcement body or regulatory agency, exercise
                                or defend our legal rights, or disclose your
                                information as evidence in litigation in which
                                we are involved.
                            </li>
                            <li>
                                <strong>Vital Interests:</strong> We may process
                                your information where we believe it is
                                necessary to protect your vital interests or the
                                vital interests of a third party, such as
                                situations involving potential threats to the
                                safety of any person.
                            </li>
                        </ul>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="whoshare"
                            className="text-2xl font-bold text-white"
                        >
                            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We may share information
                            in specific situations described in this section
                            and/or with the following third parties.
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>
                                <strong>Business Transfers:</strong> We may
                                share or transfer your information in connection
                                with, or during negotiations of, any merger,
                                sale of company assets, financing, or
                                acquisition of all or a portion of our business
                                to another company.
                            </li>
                        </ul>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="3pwebsites"
                            className="text-2xl font-bold text-white"
                        >
                            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We are not responsible
                            for the safety of any information that you share
                            with third parties that we may link to or who
                            advertise on our Services, but are not affiliated
                            with, our Services.
                        </p>
                        <p className="leading-relaxed">
                            The Services may link to third-party websites,
                            online services, or mobile applications and/or
                            contain advertisements from third parties that are
                            not affiliated with us and which may link to other
                            websites, services, or applications. Accordingly, we
                            do not make any guarantee regarding any such third
                            parties, and we will not be liable for any loss or
                            damage caused by the use of such third-party
                            websites, services, or applications. The inclusion
                            of a link towards a third-party website, service, or
                            application does not imply an endorsement by us. We
                            cannot guarantee the safety and privacy of data you
                            provide to any third parties. Any data collected by
                            third parties is not covered by this privacy notice.
                            We are not responsible for the content or privacy
                            and security practices and policies of any third
                            parties, including other websites, services, or
                            applications that may be linked to or from the
                            Services. You should review the policies of such
                            third parties and contact them directly to respond
                            to your questions.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="cookies"
                            className="text-2xl font-bold text-white"
                        >
                            6. DO WE USE COOKIES AND OTHER TRACKING
                            TECHNOLOGIES?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We may use cookies and
                            other tracking technologies to collect and store
                            your information.
                        </p>
                        <p className="leading-relaxed">
                            We may use cookies and similar tracking technologies
                            (like web beacons and pixels) to gather information
                            when you interact with our Services. Some online
                            tracking technologies help us maintain the security
                            of our Services and your account, prevent crashes,
                            fix bugs, save your preferences, and assist with
                            basic site functions.
                        </p>
                        <p className="leading-relaxed">
                            We also permit third parties and service providers
                            to use online tracking technologies on our Services
                            for analytics and advertising, including to help
                            manage and display advertisements, to tailor
                            advertisements to your interests, or to send
                            abandoned shopping cart reminders (depending on your
                            communication preferences). The third parties and
                            service providers use their technology to provide
                            advertising about products and services tailored to
                            your interests which may appear either on our
                            Services or on other websites.
                        </p>
                        <p className="leading-relaxed">
                            Specific information about how we use such
                            technologies and how you can refuse certain cookies
                            is set out in our Cookie Notice:{' '}
                            <a
                                href="https://gym.buddy.xyz/cookies"
                                className="text-blue-500 hover:underline"
                            >
                                gym.buddy.xyz/cookies
                            </a>
                            .
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="inforetain"
                            className="text-2xl font-bold text-white"
                        >
                            7. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We keep your information
                            for as long as necessary to fulfil the purposes
                            outlined in this privacy notice unless otherwise
                            required by law.
                        </p>
                        <p className="leading-relaxed">
                            We will only keep your personal information for as
                            long as it is necessary for the purposes set out in
                            this privacy notice, unless a longer retention
                            period is required or permitted by law (such as tax,
                            accounting, or other legal requirements). No purpose
                            in this notice will require us keeping your personal
                            information for longer than the period of time in
                            which users have an account with us.
                        </p>
                        <p className="leading-relaxed">
                            When we have no ongoing legitimate business need to
                            process your personal information, we will either
                            delete or anonymise such information, or, if this is
                            not possible (for example, because your personal
                            information has been stored in backup archives),
                            then we will securely store your personal
                            information and isolate it from any further
                            processing until deletion is possible.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="infosafe"
                            className="text-2xl font-bold text-white"
                        >
                            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> We aim to protect your
                            personal information through a system of
                            organisational and technical security measures.
                        </p>
                        <p className="leading-relaxed">
                            We have implemented appropriate and reasonable
                            technical and organisational security measures
                            designed to protect the security of any personal
                            information we process. However, despite our
                            safeguards and efforts to secure your information,
                            no electronic transmission over the Internet or
                            information storage technology can be guaranteed to
                            be 100% secure, so we cannot promise or guarantee
                            that hackers, cybercriminals, or other unauthorised
                            third parties will not be able to defeat our
                            security and improperly collect, access, steal, or
                            modify your information. Although we will do our
                            best to protect your personal information,
                            transmission of personal information to and from our
                            Services is at your own risk. You should only access
                            the Services within a secure environment.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="privacyrights"
                            className="text-2xl font-bold text-white"
                        >
                            9. WHAT ARE YOUR PRIVACY RIGHTS?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> In some regions, such as
                            the European Economic Area (EEA), United Kingdom
                            (UK), and Switzerland, you have rights that allow
                            you greater access to and control over your personal
                            information. You may review, change, or terminate
                            your account at any time, depending on your country,
                            province, or state of residence.
                        </p>
                        <p className="leading-relaxed">
                            In some regions (like the EEA, UK, and Switzerland),
                            you have certain rights under applicable data
                            protection laws. These may include the right (i) to
                            request access and obtain a copy of your personal
                            information, (ii) to request rectification or
                            erasure; (iii) to restrict the processing of your
                            personal information; (iv) if applicable, to data
                            portability; and (v) not to be subject to automated
                            decision-making. In certain circumstances, you may
                            also have the right to object to the processing of
                            your personal information. You can make such a
                            request by contacting us by using the contact
                            details provided in the section{' '}
                            <a
                                href="#contact"
                                className="text-blue-500 hover:underline"
                            >
                                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </a>{' '}
                            below.
                        </p>
                        <p className="leading-relaxed">
                            We will consider and act upon any request in
                            accordance with applicable data protection laws.
                        </p>
                        <p className="leading-relaxed">
                            If you are located in the EEA or UK and you believe
                            we are unlawfully processing your personal
                            information, you also have the right to complain to
                            your{' '}
                            <a
                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                target="_blank"
                                className="text-blue-500 hover:underline"
                            >
                                Member State data protection authority
                            </a>{' '}
                            or{' '}
                            <a
                                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                target="_blank"
                                className="text-blue-500 hover:underline"
                            >
                                UK data protection authority
                            </a>
                            .
                        </p>
                        <p className="leading-relaxed">
                            If you are located in Switzerland, you may contact
                            the{' '}
                            <a
                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                target="_blank"
                                className="text-blue-500 hover:underline"
                            >
                                Federal Data Protection and Information
                                Commissioner
                            </a>
                            .
                        </p>

                        <h3
                            id="withdrawconsent"
                            className="text-xl font-bold text-white mt-6"
                        >
                            Withdrawing your consent
                        </h3>
                        <p className="leading-relaxed">
                            If we are relying on your consent to process your
                            personal information, you have the right to withdraw
                            your consent at any time. You can withdraw your
                            consent at any time by contacting us by using the
                            contact details provided in the section{' '}
                            <a
                                href="#contact"
                                className="text-blue-500 hover:underline"
                            >
                                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </a>{' '}
                            below or updating your preferences.
                        </p>
                        <p className="leading-relaxed">
                            However, please note that this will not affect the
                            lawfulness of the processing before its withdrawal
                            nor will it affect the processing of your personal
                            information conducted in reliance on lawful
                            processing grounds other than consent.
                        </p>

                        <h3 className="text-xl font-bold text-white mt-6">
                            Account Information
                        </h3>
                        <p className="leading-relaxed">
                            If you would at any time like to review or change
                            the information in your account or terminate your
                            account, you can:
                        </p>
                        <ul className="list-disc list-inside space-y-2">
                            <li>
                                Log in to your account settings and update your
                                user account.
                            </li>
                        </ul>
                        <p className="leading-relaxed">
                            Upon your request to terminate your account, we will
                            deactivate or delete your account and information
                            from our active databases. However, we may retain
                            some information in our files to prevent fraud,
                            troubleshoot problems, assist with any
                            investigations, enforce our legal terms and/or
                            comply with applicable legal requirements.
                        </p>

                        <h3 className="text-xl font-bold text-white mt-6">
                            Cookies and similar technologies
                        </h3>
                        <p className="leading-relaxed">
                            Most Web browsers are set to accept cookies by
                            default. If you prefer, you can usually choose to
                            set your browser to remove cookies and to reject
                            cookies. If you choose to remove cookies or reject
                            cookies, this could affect certain features or
                            services of our Services. You may also{' '}
                            <a
                                href="https://www.aboutads.info/choices/"
                                target="_blank"
                                className="text-blue-500 hover:underline"
                            >
                                opt out of interest-based advertising by
                                advertisers
                            </a>{' '}
                            on our Services. For further information, please see
                            our Cookie Notice:{' '}
                            <a
                                href="https://gym.buddy.xyz/cookies"
                                className="text-blue-500 hover:underline"
                            >
                                gym.buddy.xyz/cookies
                            </a>
                            .
                        </p>
                        <p className="leading-relaxed">
                            If you have questions or comments about your privacy
                            rights, you may email us at{' '}
                            <a
                                href="mailto:support@gym-buddy.xyz"
                                className="text-blue-500 hover:underline"
                            >
                                support@gym-buddy.xyz
                            </a>
                            .
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2 id="DNT" className="text-2xl font-bold text-white">
                            10. CONTROLS FOR DO-NOT-TRACK FEATURES
                        </h2>
                        <p className="leading-relaxed">
                            Most web browsers and some mobile operating systems
                            and mobile applications include a Do-Not-Track
                            ("DNT") feature or setting you can activate to
                            signal your privacy preference not to have data
                            about your online browsing activities monitored and
                            collected. At this stage, no uniform technology
                            standard for recognizing and implementing DNT
                            signals has been finalized. As such, we do not
                            currently respond to DNT browser signals or any
                            other mechanism that automatically communicates your
                            choice not to be tracked online. If a standard for
                            online tracking is adopted that we must follow in
                            the future, we will inform you about that practice
                            in a revised version of this privacy notice.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="policyupdates"
                            className="text-2xl font-bold text-white"
                        >
                            11. DO WE MAKE UPDATES TO THIS NOTICE?
                        </h2>
                        <p className="leading-relaxed">
                            <strong>In Short:</strong> Yes, we will update this
                            notice as necessary to stay compliant with relevant
                            laws.
                        </p>
                        <p className="leading-relaxed">
                            We may update this privacy notice from time to time.
                            The updated version will be indicated by an updated
                            "Revised" date at the top of this privacy notice. If
                            we make material changes to this privacy notice, we
                            may notify you either by prominently posting a
                            notice of such changes or by directly sending you a
                            notification. We encourage you to review this
                            privacy notice frequently to be informed of how we
                            are protecting your information.
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="contact"
                            className="text-2xl font-bold text-white"
                        >
                            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </h2>
                        <p className="leading-relaxed">
                            If you have questions or comments about this notice,
                            you may email us at{' '}
                            <a
                                href="mailto:support@gym-buddy.xyz"
                                className="text-blue-500 hover:underline"
                            >
                                support@gym-buddy.xyz
                            </a>
                        </p>
                    </div>

                    <div className="space-y-4">
                        <h2
                            id="request"
                            className="text-2xl font-bold text-white"
                        >
                            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA
                            WE COLLECT FROM YOU?
                        </h2>
                        <p className="leading-relaxed">
                            Based on the applicable laws of your country, you
                            may have the right to request access to the personal
                            information we collect from you, details about how
                            we have processed it, correct inaccuracies, or
                            delete your personal information. You may also have
                            the right to withdraw your consent to our processing
                            of your personal information. These rights may be
                            limited in some circumstances by applicable law. To
                            request to review, update, or delete your personal
                            information, please visit:{' '}
                            <a
                                href="https://gym-buddy.xyz/data-request"
                                className="text-blue-500 hover:underline"
                            >
                                gym-buddy.xyz/data-request
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
