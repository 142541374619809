import React, { useState, useEffect } from 'react';
import {
    getWorkoutGroupsApi,
    createWorkoutGroupApi,
} from '../services/WorkoutGroupService';

const WorkoutGroupManager: React.FC<{
    onGroupSelect: (groupId: number) => void;
    onCreateNewGroup: () => void;
}> = ({ onGroupSelect, onCreateNewGroup }) => {
    const [groups, setGroups] = useState<any[]>([]);
    const [groupName, setGroupName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isCreatingNewGroup, setIsCreatingNewGroup] =
        useState<boolean>(false);

    useEffect(() => {
        loadGroups();
    }, []);

    const loadGroups = async () => {
        try {
            const res = await getWorkoutGroupsApi();
            if (res && res.data) {
                setGroups(res.data);
            }
        } catch (e) {
            console.error('Failed to load workout groups', e);
        }
    };

    const handleCreateGroup = async () => {
        try {
            await createWorkoutGroupApi({ name: groupName, description });
            setGroupName('');
            setDescription('');
            setIsCreatingNewGroup(false);
            loadGroups();
        } catch (e) {
            console.error('Failed to create workout group', e);
        }
    };

    return (
        <div className="bg-gray-800 p-1 rounded-lg shadow-md mb-4">
            <div className="flex mb-4">
                {groups.map((group) => (
                    <button
                        key={group.id}
                        onClick={() => onGroupSelect(group.id)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                        {group.name}
                    </button>
                ))}
                <button
                    onClick={() => setIsCreatingNewGroup(true)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                >
                    +
                </button>
            </div>
            {isCreatingNewGroup && (
                <div className="mt-4">
                    <input
                        type="text"
                        placeholder="Group Name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        className="input input-bordered w-full bg-gray-700 text-white mb-2"
                    />
                    <textarea
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="input input-bordered w-full bg-gray-700 text-white mb-2"
                    ></textarea>
                    <button
                        onClick={handleCreateGroup}
                        className="bg-green-500 text-white p-2 rounded w-full"
                    >
                        Create Group
                    </button>
                </div>
            )}
        </div>
    );
};

export default WorkoutGroupManager;
