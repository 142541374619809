import axios, { AxiosError } from 'axios';
import { Invite } from '../models/userModel';
import { handleError } from '../utils/ErrorHandler';

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface APIError {
    error: string;
}

const apiClient = axios.create({
    baseURL: BASE_URL,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        console.log(token);
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const getInvitesAPI = async (): Promise<Invite[] | APIError> => {
    try {
        const response = await apiClient.get<Invite[]>(`/invite`);
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};
