import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../context/useAuth';
import { toast } from 'react-toastify';

type UpdateUserForm = {
    email?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    password: string;
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format'),
    username: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    password: Yup.string()
        .required('Password is required')
        .min(3, 'The password must be at least 3 characters')
        .max(20, 'The password may be a maximum of 20 characters.'),
});

const UserSettingsPage: React.FC = () => {
    const { user, updateUser } = useAuth();
    const [avatar, setAvatar] = useState<File | null>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateUserForm>({
        resolver: yupResolver(validationSchema),
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setAvatar(e.target.files[0]);
        }
    };

    const onSubmit = async (data: UpdateUserForm) => {
        const response = await updateUser(data, avatar ?? undefined);
        if (response.success) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-3xl font-bold mb-4">User Settings</h2>
            <p className="text-gray-400 mb-8">
                Here you can update your user profile.
            </p>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-gray-800 p-6 rounded-lg shadow-lg"
            >
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="email"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        defaultValue={user?.email}
                        {...register('email')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.email && (
                        <p className="text-red-500 text-xs italic">
                            {errors.email.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="username"
                    >
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        defaultValue={user?.username}
                        {...register('username')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.username && (
                        <p className="text-red-500 text-xs italic">
                            {errors.username.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="firstName"
                    >
                        First Name
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        defaultValue={user?.firstName}
                        {...register('firstName')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.firstName && (
                        <p className="text-red-500 text-xs italic">
                            {errors.firstName.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="lastName"
                    >
                        Last Name
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        defaultValue={user?.lastName}
                        {...register('lastName')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.lastName && (
                        <p className="text-red-500 text-xs italic">
                            {errors.lastName.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="password"
                    >
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        {...register('password')}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                    {errors.password && (
                        <p className="text-red-500 text-xs italic">
                            {errors.password.message}
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <label
                        className="block text-gray-300 text-sm font-bold mb-2"
                        htmlFor="avatar"
                    >
                        Avatar
                    </label>
                    <input
                        type="file"
                        id="avatar"
                        onChange={handleFileChange}
                        className="shadow appearance-none border border-gray-600 rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Update Profile
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserSettingsPage;
