import React from 'react';

const CookiePolicy: React.FC = () => {
    return (
        <div className="bg-gray-800 text-gray-300 p-6">
            <div className="max-w-2xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-white">
                        COOKIES POLICY
                    </h1>
                    <p className="text-sm text-gray-400 mt-2">
                        Last updated: May 30, 2024
                    </p>
                </div>

                <div className="space-y-6">
                    <p className="leading-relaxed">
                        This Cookies Policy explains what Cookies are and how We
                        use them. You should read this policy so You can
                        understand what type of cookies We use, or the
                        information We collect using Cookies and how that
                        information is used.
                    </p>
                    <p className="leading-relaxed">
                        Cookies do not typically contain any information that
                        personally identifies a user, but personal information
                        that we store about You may be linked to the information
                        stored in and obtained from Cookies. For further
                        information on how We use, store and keep your personal
                        data secure, see our Privacy Policy.
                    </p>
                    <p className="leading-relaxed">
                        We do not store sensitive personal information, such as
                        mailing addresses, account passwords, etc. in the
                        Cookies We use.
                    </p>

                    <h2 className="text-2xl font-bold text-white">
                        Interpretation and Definitions
                    </h2>
                    <h4 className="text-xl font-semibold text-white">
                        Interpretation
                    </h4>
                    <p className="leading-relaxed">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or in
                        plural.
                    </p>

                    <h4 className="text-xl font-semibold text-white">
                        Definitions
                    </h4>
                    <p className="leading-relaxed">
                        For the purposes of this Cookies Policy:
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            <strong>Company</strong> (referred to as either "the
                            Company", "We", "Us" or "Our" in this Cookies
                            Policy) refers to Gym Buddy.
                        </li>
                        <li>
                            <strong>Cookies</strong> means small files that are
                            placed on Your computer, mobile device or any other
                            device by a website, containing details of your
                            browsing history on that website among its many
                            uses.
                        </li>
                        <li>
                            <strong>Website</strong> refers to Gym Buddy,
                            accessible from{' '}
                            <a
                                href="https://www.gym-buddy.xyz"
                                rel="external nofollow noopener"
                                target="_blank"
                                className="text-blue-500 hover:underline"
                            >
                                https://www.gym-buddy.xyz
                            </a>
                        </li>
                        <li>
                            <strong>You</strong> means the individual accessing
                            or using the Website, or a company, or any legal
                            entity on behalf of which such individual is
                            accessing or using the Website, as applicable.
                        </li>
                    </ul>

                    <h2 className="text-2xl font-bold text-white">
                        The use of the Cookies
                    </h2>
                    <h4 className="text-xl font-semibold text-white">
                        Type of Cookies We Use
                    </h4>
                    <p className="leading-relaxed">
                        Cookies can be "Persistent" or "Session" Cookies.
                        Persistent Cookies remain on your personal computer or
                        mobile device when You go offline, while Session Cookies
                        are deleted as soon as You close your web browser.
                    </p>
                    <p className="leading-relaxed">
                        We use both session and persistent Cookies for the
                        purposes set out below:
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            <p>
                                <strong>Necessary / Essential Cookies</strong>
                            </p>
                            <p>Type: Session Cookies</p>
                            <p>Administered by: Us</p>
                            <p>
                                Purpose: These Cookies are essential to provide
                                You with services available through the Website
                                and to enable You to use some of its features.
                                They help to authenticate users and prevent
                                fraudulent use of user accounts. Without these
                                Cookies, the services that You have asked for
                                cannot be provided, and We only use these
                                Cookies to provide You with those services.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Functionality Cookies</strong>
                            </p>
                            <p>Type: Persistent Cookies</p>
                            <p>Administered by: Us</p>
                            <p>
                                Purpose: These Cookies allow us to remember
                                choices You make when You use the Website, such
                                as remembering your login details or language
                                preference. The purpose of these Cookies is to
                                provide You with a more personal experience and
                                to avoid You having to re-enter your preferences
                                every time You use the Website.
                            </p>
                        </li>
                    </ul>

                    <h4 className="text-xl font-semibold text-white">
                        Your Choices Regarding Cookies
                    </h4>
                    <p className="leading-relaxed">
                        If You prefer to avoid the use of Cookies on the
                        Website, first You must disable the use of Cookies in
                        your browser and then delete the Cookies saved in your
                        browser associated with this website. You may use this
                        option for preventing the use of Cookies at any time.
                    </p>
                    <p className="leading-relaxed">
                        If You do not accept Our Cookies, You may experience
                        some inconvenience in your use of the Website and some
                        features may not function properly.
                    </p>
                    <p className="leading-relaxed">
                        If You'd like to delete Cookies or instruct your web
                        browser to delete or refuse Cookies, please visit the
                        help pages of your web browser.
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            <p>
                                For the Chrome web browser, please visit this
                                page from Google:{' '}
                                <a
                                    href="https://support.google.com/accounts/answer/32050"
                                    rel="external nofollow noopener"
                                    target="_blank"
                                    className="text-blue-500 hover:underline"
                                >
                                    https://support.google.com/accounts/answer/32050
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                For the Internet Explorer web browser, please
                                visit this page from Microsoft:{' '}
                                <a
                                    href="http://support.microsoft.com/kb/278835"
                                    rel="external nofollow noopener"
                                    target="_blank"
                                    className="text-blue-500 hover:underline"
                                >
                                    http://support.microsoft.com/kb/278835
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                For the Firefox web browser, please visit this
                                page from Mozilla:{' '}
                                <a
                                    href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                                    rel="external nofollow noopener"
                                    target="_blank"
                                    className="text-blue-500 hover:underline"
                                >
                                    https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                For the Safari web browser, please visit this
                                page from Apple:{' '}
                                <a
                                    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                                    rel="external nofollow noopener"
                                    target="_blank"
                                    className="text-blue-500 hover:underline"
                                >
                                    https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                                </a>
                            </p>
                        </li>
                    </ul>
                    <p className="leading-relaxed">
                        For any other web browser, please visit your web
                        browser's official web pages.
                    </p>

                    <h4 className="text-xl font-semibold text-white">
                        More Information about Cookies
                    </h4>
                    <p className="leading-relaxed">
                        You can learn more about cookies:{' '}
                        <a
                            href="https://www.freeprivacypolicy.com/blog/cookies/"
                            target="_blank"
                            className="text-blue-500 hover:underline"
                        >
                            Cookies: What Do They Do?
                        </a>
                        .
                    </p>

                    <h4 className="text-xl font-semibold text-white">
                        Contact Us
                    </h4>
                    <p className="leading-relaxed">
                        If you have any questions about this Cookies Policy, You
                        can contact us:
                    </p>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            By email:{' '}
                            <a
                                href="mailto:support@gym-buddy.xyz"
                                className="text-blue-500 hover:underline"
                            >
                                support@gym-buddy.xyz
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicy;
