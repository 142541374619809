import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
    confirmEmailAPI,
    requestNewEmailAuthCodeAPI,
} from '../services/AuthService';

const authCodeSchema = Yup.object().shape({
    authCode: Yup.string().required('Authentication code is required'),
});

type EmailConfirmComponentProps = {
    email: string;
    onSuccess: () => void;
};

const EmailConfirmComponent: React.FC<EmailConfirmComponentProps> = ({
    email,
    onSuccess,
}) => {
    const {
        register: registerAuthCode,
        handleSubmit: handleAuthCodeSubmit,
        formState: { errors: authCodeErrors },
    } = useForm<{ authCode: string }>({
        resolver: yupResolver(authCodeSchema),
    });

    const handleConfirmEmail = async (data: { authCode: string }) => {
        try {
            const response = await confirmEmailAPI(data.authCode);
            if ('success' in response && response.success) {
                toast.success(response.message);
                onSuccess();
            } else if ('error' in response) {
                toast.error(response.error);
            } else {
                toast.error('Unexpected error occurred');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleNewAuthCode = async () => {
        try {
            const response = await requestNewEmailAuthCodeAPI(email);
            if ('success' in response && response.success) {
                toast.success(response.message);
                onSuccess();
            } else if ('error' in response) {
                toast.error(response.error);
            } else {
                toast.error('Unexpected error occurred');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            <h1 className="text-xl font-bold leading-tight tracking-tight mb-3 md:text-2xl text-white">
                Confirm Email
            </h1>
            <p className="text-gray-400">
                Please enter the authentication code sent to your email to
                confirm your registration for {email}.
            </p>
            <form
                className="space-y-4 md:space-y-6"
                onSubmit={handleAuthCodeSubmit(handleConfirmEmail)}
            >
                <div>
                    <label
                        htmlFor="authCode"
                        className="block my-2 text-sm font-medium text-white"
                    >
                        Authentication Code
                    </label>
                    <input
                        type="text"
                        id="authCode"
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Authentication Code"
                        {...registerAuthCode('authCode')}
                    />
                    {authCodeErrors.authCode && (
                        <p className="text-red-400">
                            {authCodeErrors.authCode.message}
                        </p>
                    )}
                </div>
                <button
                    type="submit"
                    className="w-full text-white bg-green-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Confirm
                </button>
                {/* <button
                    type="button"
                    onClick={handleNewAuthCode}
                    className="w-full text-white bg-blue-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Request new
                </button> */}
            </form>
        </div>
    );
};

export default EmailConfirmComponent;
