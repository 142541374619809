import React from 'react';
import bg1 from '../assets/images/bg1.webp';

const HomePage: React.FC = () => {
    return (
        <div className="bg-gray-800 min-h-screen text-white">
            <div className="container mx-auto px-4 py-12">
                <header className="mb-12 text-center">
                    <h1 className="text-4xl font-bold">
                        Optimize Your Fitness Journey
                    </h1>
                    <p className="mt-4 text-lg text-gray-400">
                        Discover a personalized approach to fitness with our
                        comprehensive workout platform.
                    </p>
                    <p className="mt-2 text-sm font-bold text-yellow-500">
                        Please note: This project is still in Beta.
                    </p>
                </header>

                {/* Featured Section with Background Image */}
                <section
                    className="bg-black bg-opacity-50 bg-fixed bg-center bg-cover"
                    style={{
                        backgroundImage: `url(${bg1})`,
                        filter: 'grayscale(100%)',
                        backgroundPosition: 'center 90%',
                    }}
                >
                    <div className="py-12 text-center">
                        <h2 className="text-3xl font-bold">
                            Challenge Yourself
                        </h2>
                        <p className="text-gray-300 text-xl mt-4">
                            Join challenges and compete with others to push your
                            limits.
                        </p>
                    </div>
                </section>

                {/* Responsive Grid Layout */}
                <section className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
                    <div className="p-6 bg-gray-900 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-semibold mb-4">
                            A Selection of Over 200 Exercises
                        </h2>
                        <p className="text-gray-300">
                            Explore a vast library of exercises designed to
                            target every muscle group. Whether you're a beginner
                            or an experienced athlete, find exercises that fit
                            your level and goals.
                        </p>
                    </div>
                    <div className="p-6 bg-gray-900 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-semibold mb-4">
                            Track Your Gym Workouts with Ease
                        </h2>
                        <p className="text-gray-300">
                            Simplify your workout tracking with our intuitive
                            interface. Log your sets, reps, and weights, and
                            keep all your workout details in one place for easy
                            access.
                        </p>
                    </div>
                    <div className="p-6 bg-gray-900 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-semibold mb-4">
                            Visualize Your Progress
                        </h2>
                        <p className="text-gray-300">
                            See your progress in real-time with our detailed
                            charts and reports. Monitor your improvements and
                            get motivated by witnessing your own fitness journey
                            unfold.
                        </p>
                    </div>
                    <div className="p-6 bg-gray-900 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-semibold mb-4">
                            Track Your Body Measurements
                        </h2>
                        <p className="text-gray-300">
                            Keep track of your body measurements including
                            weight, height, shoulders, chest, waist, thighs,
                            calves, and biceps. Monitor your physical changes
                            over time to stay motivated and adjust your training
                            plans accordingly.
                        </p>
                    </div>
                    <div className="p-6 bg-gray-900 rounded-lg shadow-lg relative">
                        <div className="absolute top-0 right-0 p-2 text-blue-500 text-sm font-bold">
                            COMING SOON
                        </div>
                        <h2 className="text-2xl font-semibold mb-4">
                            AI-Powered Custom Training Plans
                        </h2>
                        <p className="text-gray-300">
                            Let our AI-powered system generate a tailored plan
                            that adapts to your fitness level and objectives.
                            Achieve your goals with plans that evolve with you.
                        </p>
                    </div>
                </section>

                {/* Call to Action Banner */}
                <section className="mt-12 bg-gray-700 py-8 text-center">
                    <h2 className="text-2xl font-semibold">
                        Ready to Start Your Journey?
                    </h2>
                    <p className="text-gray-300 mt-2 mb-4">
                        Sign up today and transform your life.
                    </p>
                    <a
                        href="register"
                        className="mt-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Join Now
                    </a>
                </section>
            </div>
        </div>
    );
};

export default HomePage;
