import React, { useEffect, useState } from 'react';
import { getInvitesAPI } from '../../services/InviteService';
import { Invite } from '../../models/userModel';
import { toast } from 'react-toastify';

const InvitesPage: React.FC = () => {
    const [invites, setInvites] = useState<Invite[]>([]);

    useEffect(() => {
        const fetchInvites = async () => {
            const result = await getInvitesAPI();
            if ('error' in result) {
                toast.error(result.error);
            } else {
                setInvites(result);
            }
        };

        fetchInvites();
    }, []);

    const unusedInvites = invites.filter((invite) => !invite.usedByUser);
    const usedInvites = invites.filter((invite) => invite.usedByUser);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4 text-white">My Invites</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-gray-800 text-white rounded-lg shadow-lg">
                    <thead>
                        <tr className="bg-gray-700">
                            <th className="py-2 px-4">Invite Key</th>
                            <th className="py-2 px-4">Used By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unusedInvites.map((invite) => (
                            <tr
                                key={invite.id}
                                className="border-t border-gray-700"
                            >
                                <td className="py-2 px-4">{invite.key}</td>
                                <td className="py-2 px-4">Unused</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {usedInvites.length > 0 && (
                <>
                    <h2 className="text-xl font-bold mt-8 mb-4 text-white">
                        Used Invites
                    </h2>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-gray-800 text-white rounded-lg shadow-lg">
                            <thead>
                                <tr className="bg-gray-700">
                                    <th className="py-2 px-4">Invite Key</th>
                                    <th className="py-2 px-4">Used By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usedInvites.map((invite) => (
                                    <tr
                                        key={invite.id}
                                        className="border-t border-gray-700"
                                    >
                                        <td className="py-2 px-4">
                                            {invite.key}
                                        </td>
                                        <td className="py-2 px-4">
                                            {invite.usedByUser?.username}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default InvitesPage;
