import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import HomePage from '../pages/HomePage';
import ProtectedRoute from './ProtectedRoute';
import WorkoutPage from '../pages/WorkoutPage';
import BodyStatsPage from '../pages/BodyStatsPage';
import UserSettingsPage from '../pages/UserSettingsPage';
import InvitesPage from '../pages/Auth/InvitesPage';
import PrivacyPolicy from '../pages/Policies/PrivatePolicy';
import CookiePolicy from '../pages/Policies/CookiePolicy';
import TOS from '../pages/Policies/TOS';
import LoginPage from '../pages/Auth/LoginPage';
import RegisterPage from '../pages/Auth/RegisterPage';
import NotFound from '../pages/Utils/NotFoundPage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            { path: '', element: <HomePage /> },
            { path: 'login', element: <LoginPage /> },
            { path: 'register', element: <RegisterPage /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'cookie-policy', element: <CookiePolicy /> },
            { path: 'tos', element: <TOS /> },
            {
                path: 'user-settings',
                element: (
                    <ProtectedRoute>
                        <UserSettingsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'invites',
                element: (
                    <ProtectedRoute>
                        <InvitesPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'bodystats',
                element: (
                    <ProtectedRoute>
                        <BodyStatsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'workouts',
                element: (
                    <ProtectedRoute>
                        <WorkoutPage />
                    </ProtectedRoute>
                ),
            },
            { path: '*', element: <NotFound /> },
        ],
    },
]);
