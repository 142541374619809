import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    fetchLatestBodyStats,
    saveBodyStats,
} from '../services/BodyStatsService';
import StatComponent from '../components/BodyStat';

interface Measurement {
    name: string;
    value: number;
}

type BodyStatKeys =
    | 'bodyWeight'
    | 'bodyHeight'
    | 'shoulder'
    | 'chest'
    | 'waist'
    | 'thighLeft'
    | 'thighRight'
    | 'calfLeft'
    | 'calfRight'
    | 'bicepsLeft'
    | 'bicepsRight';

interface BodyStat {
    bodyWeight: Measurement[];
    bodyHeight: Measurement[];
    shoulder: Measurement[];
    chest: Measurement[];
    waist: Measurement[];
    thighLeft: Measurement[];
    thighRight: Measurement[];
    calfLeft: Measurement[];
    calfRight: Measurement[];
    bicepsLeft: Measurement[];
    bicepsRight: Measurement[];
}

const initializeDefaultBodyStats = (): BodyStat => {
    const keys: BodyStatKeys[] = [
        'bodyWeight',
        'bodyHeight',
        'shoulder',
        'chest',
        'waist',
        'thighLeft',
        'thighRight',
        'calfLeft',
        'calfRight',
        'bicepsLeft',
        'bicepsRight',
    ];
    const defaultStats = {} as BodyStat;
    keys.forEach((key) => {
        defaultStats[key] = [];
    });
    return defaultStats;
};

const BodyStatsPage: React.FC = () => {
    const [bodyStats, setBodyStats] = useState<BodyStat>(
        initializeDefaultBodyStats()
    );
    const [currentInputs, setCurrentInputs] = useState<{
        [key in BodyStatKeys]?: string;
    }>({});
    const [showInstructions, setShowInstructions] = useState<boolean>(false);

    useEffect(() => {
        const loadInitialData = async () => {
            const latestStats = await fetchLatestBodyStats();
            if (latestStats && latestStats.length > 0) {
                latestStats.sort(
                    (a, b) =>
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime()
                );
                const formattedStats: BodyStat = initializeDefaultBodyStats();
                latestStats.forEach((stat) => {
                    const date = new Date(stat.createdAt).toLocaleDateString(
                        'de-DE',
                        {
                            day: '2-digit',
                            month: '2-digit',
                        }
                    );
                    (Object.keys(stat) as (keyof typeof stat)[]).forEach(
                        (key) => {
                            if (key !== 'id' && key !== 'createdAt') {
                                formattedStats[key as BodyStatKeys].push({
                                    name: date,
                                    value: stat[key],
                                });
                            }
                        }
                    );
                });
                setBodyStats(formattedStats);
            } else {
                setBodyStats(initializeDefaultBodyStats());
            }
        };
        loadInitialData();
    }, []);

    const handleSave = async () => {
        const formattedData = (Object.keys(bodyStats) as BodyStatKeys[]).reduce(
            (acc, key) => {
                const currentValue = parseFloat(currentInputs[key] || '');
                if (isNaN(currentValue)) {
                    acc[key] =
                        bodyStats[key].length > 0
                            ? bodyStats[key][bodyStats[key].length - 1].value
                            : 0;
                } else {
                    acc[key] = currentValue;
                }
                return acc;
            },
            {} as any
        );

        await saveBodyStats(formattedData);
        toast.success('Data saved!');
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: BodyStatKeys
    ) => {
        const { value } = event.target;
        setCurrentInputs((prevInputs) => ({
            ...prevInputs,
            [key]: value,
        }));
    };

    return (
        <div className="container mx-auto p-4 text-white min-h-screen">
            <h2 className="text-3xl font-bold mb-4">Track Body Stats</h2>
            <p className="text-gray-400 mb-8">
                On this page, you can track various body measurements over time.
                Enter your current measurements and save them to see the
                progress. <br></br>Click{' '}
                <span
                    className="text-blue-500 underline cursor-pointer"
                    onClick={() => setShowInstructions(!showInstructions)}
                >
                    here
                </span>{' '}
                to see instructions on how to take measurements.
            </p>
            {showInstructions && (
                <div className="bg-gray-700 p-6 rounded-lg mb-4 flex flex-col sm:flex-row items-start">
                    <div className="sm:w-2/3 mb-4 sm:mb-0">
                        <h3 className="text-lg font-bold mb-2">
                            How to Take Measurements
                        </h3>
                        <ul className="list-disc list-inside text-gray-300">
                            <li>
                                <strong>Body Weight:</strong> Weigh yourself in
                                the morning after using the bathroom.
                            </li>
                            <li>
                                <strong>Body Height:</strong> Stand straight
                                against a wall and measure from the floor to the
                                top of your head.
                            </li>
                            <li>
                                <strong>Shoulder:</strong> Measure around the
                                widest part of your shoulders.
                            </li>
                            <li>
                                <strong>Chest:</strong> Measure around the
                                fullest part of your chest.
                            </li>
                            <li>
                                <strong>Waist:</strong> Measure around the
                                narrowest part of your waist.
                            </li>
                            <li>
                                <strong>Thigh Left/Right:</strong> Measure
                                around the fullest part of your thigh.
                            </li>
                            <li>
                                <strong>Calf Left/Right:</strong> Measure around
                                the widest part of your calf.
                            </li>
                            <li>
                                <strong>Biceps Left/Right:</strong> Measure
                                around the widest part of your biceps while
                                flexed.
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {(Object.keys(bodyStats) as BodyStatKeys[]).map((key) => (
                    <StatComponent
                        key={key}
                        label={key
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, (str) => str.toUpperCase())}
                        data={bodyStats[key]}
                        value={currentInputs[key] || ''} // Keep input field empty if no value
                        onChange={(event) => handleChange(event, key)}
                        unit={key.includes('Weight') ? 'kg' : 'cm'}
                    />
                ))}
            </div>
            <button
                onClick={handleSave}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
                Save Data
            </button>
        </div>
    );
};

export default BodyStatsPage;
